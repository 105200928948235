import { useOutletContext } from "react-router-dom";
import ParentCallEnd from "V2.0/HomePages/HomeModule/EndCall/ParentCallEnd";

export function EndCall() {
  const { projectId, settings, fetchSettings } =
    useOutletContext();

  return (
    <ParentCallEnd
      projectId={projectId}
      settings={settings}
      fetchSettings={fetchSettings}
    />
  );
}
