import http from "./httpService";
const apiEndpoint = "/supwebhooks";

function subscribetoWebhook({
  projectId,
  eventName,
  endpointUrl,
  authRequired,
  authKey,
  webhookName,
}) {
  return http.post(apiEndpoint + "/" + "subscribe", {
    projectId,
    eventName,
    endpointUrl,
    authRequired,
    authKey,
    webhookName,
  });
}

function getAllWebhooks({ projectId }) {
  return http.get(`${apiEndpoint}/getWebhooks/${projectId}`);
}

function deleteWebhook({ projectId, webhookId }) {
  return http.delete(`${apiEndpoint}/unsubscribe/${projectId}/${webhookId}`);
}

export { subscribetoWebhook, getAllWebhooks, deleteWebhook };
