import React, { useState, useEffect } from "react";
import { Button } from "V2.0/common/button";
import { PlusIcon, XMarkIcon } from "@heroicons/react/16/solid";
import { Dialog, DialogBody, DialogTitle } from "V2.0/common/dialog";
import { AgentForm, editAgentButtons } from "../../../common/Agent_form";
import { whatsappAgentFormSchema } from "../../../models/schema";
import { generatewhatsappAgentFormTemplate } from "../../../models/template";
import { useUpdateHumanAgent } from "../../../models/useUpdateHumanAgent";
import { getWhatsappAgent } from "../../../models/get_agents";
import { message } from "antd";
import { useWhatsappTemplates } from "V2.0/HomePages/Integrations/Whatsapp/models/useWhatsappTemplates";

export function EditAgent({
  agent,
  isOpen,
  onClose,
  projectId,
  refetchAgents,
}) {
  const { loading, setLoading, handleUpdateHumanAgent } = useUpdateHumanAgent();

  function handleSubmit(data) {
    const onSuccess = () => {
      refetchAgents();
      message.success("Agent Updated");
      onClose();
    };

    const onError = () => {
      message.error("Failed to update agent");
      onClose();
    };

    handleUpdateHumanAgent({
      projectId,
      agentId: agent._id,
      data,
      onSuccess,
      onError,
    });
  }

  const { templates } = useWhatsappTemplates(projectId);

  const whatsappAgentFormTemplate =
    generatewhatsappAgentFormTemplate(templates.data);

  return (
    <Dialog className="" open={isOpen} onClose={onClose} size="2xl">
      <DialogTitle>
        <div class="text-lg flex flex-row justify-between">
          <div>Edit Agent</div>
          <div class="text-lg">
            <Button plain onClick={onClose}>
              <XMarkIcon />
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogBody>
        <AgentForm
          agent={getWhatsappAgent(agent)}
          loading={loading}
          setLoading={setLoading}
          projectId={projectId}
          refetchAgents={refetchAgents}
          onCancel={onClose}
          onSubmit={handleSubmit}
          buttons={editAgentButtons}
          agentFormSchema={whatsappAgentFormSchema}
          agentFormTemplate={whatsappAgentFormTemplate}
        />
      </DialogBody>
    </Dialog>
  );
}
