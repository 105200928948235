import { useState } from "react";
import Joi from "joi-browser";

import { useWhatsappAddPhoneNumber } from "V2.0/HomePages/Integrations/Whatsapp/models/useWhatsappAddPhoneNumber";

import { PhoneIcon, PlusIcon } from "@heroicons/react/24/solid";
import { message } from "antd";

import { Button } from "V2.0/common/button";
import { Dialog, DialogBody, DialogTitle } from "V2.0/common/dialog";
import Form from "V2.0/components/Form";

export function WhatsappPhoneNumbers(props) {
  const { existingConfiguration, projectId, fetchWhatsappConfiguration } =
    props;
  const { phoneNumberIds } = existingConfiguration;
  const [isOpen, setIsOpen] = useState(false);

  const { state, addPhoneNumber } = useWhatsappAddPhoneNumber();

  const onSuccess = () => {
    message.success("Phone number added");
    fetchWhatsappConfiguration(projectId);
    setIsOpen(false);
  };

  const onError = (error) => {
    message.error("Error adding phone number");
    console.error(error);
    setIsOpen(false);
  };

  const onInit = () => {
    message.loading("Adding phone number", 0.5);
  };

  const handleAdd = (data) => {
    addPhoneNumber({ projectId: projectId, data, onInit, onSuccess, onError });
  };

  return (
    <>
      <div className="flex flex-col mx-auto my-0 w-1/3 border rounded-lg">
        <div className="flex justify-between items-center border-b p-4 shadow-sm w-full pb-4 sticky top-0">
          <h3 className="font-semibold text-lg">
            Phone Numbers({phoneNumberIds.length})
          </h3>
          <Button outline onClick={() => setIsOpen(true)}>
            <PlusIcon />
            Add Phone Number
          </Button>
        </div>
        <div className="h-[600px] overflow-y-scroll">
          <ul>
            {phoneNumberIds.map((phoneNumber) => (
              <li className="border-b p-4 font-normal text-md flex items-center gap-x-2">
                <PhoneIcon className="size-6" /> {phoneNumber}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {isOpen && (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
          <DialogTitle>Add Phone Number</DialogTitle>
          <DialogBody>
            <Form
              key={JSON.stringify({ phoneNumberId: "" })}
              loading={state.loading}
              setLoading={() => {}}
              inputField={{ phoneNumberId: "" }}
              template={[
                { name: "phoneNumberId", label: "Phone Number", type: "text" },
              ]}
              schema={{
                phoneNumberId: Joi.string().required().label("Phone Number"),
              }}
              buttonInfo={{
                buttons: "2",
                primaryLabel: "Add",
                secondaryLabel: "Cancel",
              }}
              onSubmit={handleAdd}
              handleSecondary={() => setIsOpen(false)}
            />
          </DialogBody>
        </Dialog>
      )}
    </>
  );
}
