import { useState } from "react";
import httpService from "V2.0/services/httpService";

export function useWhatsappAddPhoneNumber() {
  const [state, setState] = useState({
    loading: false,
  });

  const addPhoneNumber = async ({
    projectId,
    data,
    onSuccess,
    onError,
    onInit,
  }) => {
    setState({ loading: true });
    onInit();
    try {
      const response = await httpService.post(
        `/whatsapp/manage/addPhone/${projectId}`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setState({loading: false});
        onSuccess();
      }
    } catch (error) {
      setState({loading: false});
      onError();
    }
  };

  return {
    state,
    addPhoneNumber,
  };
}
