import React from "react";
import { useOutletContext } from "react-router-dom";
import ParentTriggerSMS from "V2.0/HomePages/HomeModule/TriggerSMS/ParentTriggerSMS";

export function Triggers(props) {
  const { projects, activeProject, settings, refetchSettings } =
    useOutletContext();
  return (
    <ParentTriggerSMS
      projects={projects}
      activeProject={activeProject}
      settings={settings}
      refetchSettings={refetchSettings}
      reload={false}
      setReload={() => {}}
    />
  );
}
