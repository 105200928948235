import React, { useState, useMemo, useEffect } from "react";
import Search from "V2.0/components/Search";
import * as knowledgeService from "V2.0/services/knowledgebaseService";
import DeleteIcon from "V2.0/images/delete.svg";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "V2.0/common/alert";
import { Button } from "V2.0/common/button";
import { message } from "antd";

import {
  PencilSquareIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "V2.0/common/dialog";
import { AddTextData } from "./Texts/Add_text";
import { EditParagraph } from "./Texts/Edit_text";

function AddParagraph(props) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)} color="white">
        <PlusIcon /> Add Paragraph
      </Button>
      <Dialog size="5xl" open={open} onClose={() => {}}>
        <DialogTitle>
          <div className="flex justify-between">
            <p className="text-md font-semibold">Add Paragraph</p>
            <Button plain onClick={() => setOpen(false)}>
              <XMarkIcon />
            </Button>
          </div>
        </DialogTitle>
        <DialogBody>
          <div className="h-[460px] overflow-auto">
            <AddTextData
              {...props}
              getData={(data) => {
                setData(data);
              }}
              onClose={() => setOpen(false)}
            />
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
}

function Paragraphs({
  originalKBData,
  projects,
  activeProject,
  reload,
  setReload,
  kbLoading: paragraphLoading,
  refetch,
  setEditItem,
  kbExists,
  KB
}) {
  let [searchQuery, setSearchQuery] = useState("");
  const [key, setKey] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState({
    isOpen: false,
    item: {},
  });

  const handleBackspaceKeyPress = (event) => {
    if (event.keyCode === 8) {
      const inputValue = searchQuery.slice(0, -1);

      setSearchQuery(inputValue);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const filteredKBData = useMemo(() => {
    if (searchQuery != "") {
      return originalKBData.filter((paragraph) => {
        const lowerCasedValue = searchQuery.toLowerCase().trim();
        return paragraph.value.data.toLowerCase().includes(lowerCasedValue);
      });
    }

    return originalKBData;
  }, [originalKBData, searchQuery]);

  const handleEdit = (item) => {
    setIsEdit({
      isOpen: true,
      item,
    });
  };

  const RevokeOk = async () => {
    setLoading(true);

    try {
      const response = await knowledgeService.deleteParagraph(
        projects[activeProject]._id,
        key
      );
      if (response.status === 200) {
        message.info("Deletion succesful");
        setIsOpen(false);
        refetch(projects[activeProject]._id);
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
        setIsOpen(false);
        return;
      }
    }
  };

  const showDeleteModal = (item) => {
    setIsOpen(true);
    setKey(item.key);
  };

  return (
    <div className="p-8">
      <div className="flex mb-8 justify-between items-center pb-4">
        <p className="text-xl font-semibold">Paragraphs</p>
        <AddParagraph
          projects={projects}
          activeProject={activeProject}
          KB={originalKBData}
          reload={reload}
          setReload={setReload}
          refetch={refetch}
          kbExists={kbExists}
        />
      </div>

      <Search
        placeholder="Search for the exact phrases you have in your documents"
        onSearch={handleSearch}
        handleBackspaceKeyPress={handleBackspaceKeyPress}
        value={searchQuery}
      />

      <div className="max-h-[80dvh] overflow-scroll">
        {!paragraphLoading && filteredKBData && filteredKBData.length > 0 ? (
          <ul role="list" className="mt-4 w-full flex flex-col gap-y-4">
            {filteredKBData
              .slice()
              .reverse()
              .map((item, index) => (
                <li
                  key={index}
                  className="flex flex-col justify-between border border-b rounded-lg p-8 w-ful"
                >
                  <div className="whitespace-pre-wrap mb-4">
                    {item.value.data}
                  </div>
                  <div className="flex justify-end space-x-4">
                    <Button outline onClick={() => handleEdit(item)}>
                      Edit
                    </Button>
                    <button
                      className="text-red-500 hover:text-red-700 transition duration-150 ease-in-out"
                      onClick={() => showDeleteModal(item)}
                    >
                      <img src={DeleteIcon} alt="Delete" className="w-5 h-5" />
                    </button>
                  </div>
                </li>
              ))}
          </ul>
        ) : (
          !paragraphLoading && (
            <div className="flex flex-col gap-y-4 justify-center items-center h-[80dvh]">
              <div>
                <PencilSquareIcon className="size-12 text-gray-400" />
              </div>
              <AddParagraph
                projects={projects}
                activeProject={activeProject}
                KB={originalKBData}
                reload={reload}
                setReload={setReload}
                refetch={refetch}
              />
            </div>
          )
        )}
      </div>
      <Alert open={isOpen} onClose={() => setIsOpen(false)} size="md">
        <AlertTitle>Delete Paragraph?</AlertTitle>

        <AlertBody style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          ></div>
        </AlertBody>
        <AlertActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => RevokeOk()}>
            {loading ? "Please wait...." : "Yes, delete"}
          </Button>
        </AlertActions>
      </Alert>
      {isEdit.isOpen && (
        <EditParagraph
          isOpen={isEdit.isOpen}
          onClose={() => setIsEdit({ isOpen: false, item: {} })}
          editItem={isEdit.item}
          KB={KB}
          reload={reload}
          setReload={setReload}
          refetch={refetch}
        />
      )}
    </div>
  );
}

export default Paragraphs;
