import React from "react";
import { useOutletContext } from "react-router-dom";
import ParentExtractionModule from "V2.0/HomePages/HomeModule/Extraction/ParentExtractionModule";

export function Extractions() {
  const { projects, activeProject } = useOutletContext();

  return (
    <ParentExtractionModule projects={projects} activeProject={activeProject} />
  );
}
