import React, { useState, useEffect } from "react";

import { ExistingAPIs } from "./IndividualAPI/ExistingAPIs";

import { Button } from "../../../common/button";

import { IndividualAPI } from "./IndividualAPI";

import { PlusCircleIcon } from "@heroicons/react/16/solid";
import { useCustomAPIs } from "./hooks/useCustomAPIs";
import { individualAPI } from "./IndividualAPI/models/individualAPI";
import customAPI from "../../../images/customAPI.svg";
import { EnableCustomAPIs } from "./EnableCustomAPIs";
import { useOutletContext } from "react-router-dom";
import Breadcrumb from "V2.0/common/breadcrumb";
import { PlusIcon } from "@heroicons/react/24/outline";

const MODE = {
  ADD: "ADD",
  EDIT: "EDIT",
  NONE: null,
};

export function CustomAPIs() {
  const [mode, setMode] = useState({
    mode: MODE.NONE,
    item: null,
  });

  const { projects, activeProject, projectId, settings, refetchSettings } = useOutletContext();

  const {
    customApis,
    fetchCustomApis,
    addCustomApi,
    updateCustomApi,
    deleteCustomApi,
  } = useCustomAPIs(projectId);

  useEffect(() => {
    if (projectId) {
      fetchCustomApis();
    }
  }, [projectId]);

  return (
    <div className="w-full flex flex-col items-center justify-center p-8">
      <main className="w-full">
        <div className="flex flex-col gap-y-2">
          <div className="flex justify-between mt-4 pb-4 mb-4">
            <div className="flex items-end gap-x-2">
              {/* <img src={customAPI} alt="custom api" width="30" /> */}
              <p className="font-semibold text-lg">Custom APIs</p>
              <div className="self-end">
                <EnableCustomAPIs
                  projectId={projects[activeProject]?._id}
                  settings={settings}
                  refetchSettings={refetchSettings}
                />
              </div>
            </div>
            {(!mode.mode || mode.mode === MODE.SETTINGS) && (
              <Button
                className=""
                outline
                onClick={() => {
                  setMode({
                    mode: MODE.ADD,
                    item: individualAPI,
                  });
                }}
              >
                <PlusIcon />
                Add Custom API
              </Button>
            )}
          </div>
          <div className="max-h-[80dvh] overflow-scroll px-4">
            {(!mode.mode || mode.mode === MODE.SETTINGS) && (
              <ExistingAPIs
                customApis={customApis}
                onDelete={(id) => deleteCustomApi(id)}
                setActiveItem={(item) => setMode({ mode: MODE.EDIT, item })}
              />
            )}
            {mode.mode === MODE.EDIT && (
              <IndividualAPI
                item={mode.item}
                onConfirm={(data) => updateCustomApi(mode.item._id, data)}
                goBack={() => setMode({ mode: MODE.NONE })}
              />
            )}
            {mode.mode === MODE.ADD && (
              <IndividualAPI
                onConfirm={(data) => addCustomApi(data)}
                item={mode.item}
                goBack={() => setMode({ mode: MODE.NONE })}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
