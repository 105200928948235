import React, { useEffect, useState } from "react";
import Joi from "joi-browser";

import httpService from "../../services/httpService";
import { useOutletContext, useNavigate, Outlet } from "react-router-dom";

import { TableComponent } from "../HomeModule/CustomAPIs/components/TableComponent";
import { Button } from "../../common/button";
import { PlusIcon } from "@heroicons/react/16/solid";
import { Contact } from "./Contact";

function getContacts({ projectId }) {
  return httpService.get(`/contact/getContacts/${projectId}`);
}

function getContact({projectId, contactId}) {
  return httpService.get(`/contact/getContact/${projectId}/${contactId}`);
}

function createContact({ projectId, body }) {
  return httpService.post(`/contact/createContact/${projectId}`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function updateContact({ projectId, contactId, payload }) {
  return httpService.put(`/contact/${projectId}/${contactId}`, payload);
}

function deleteContact({ projectId, contactId }) {
  return httpService.delete(`/contact/${projectId}/${contactId}`);
}

export {
  getContact,
  getContacts,
  createContact,
  updateContact,
  deleteContact
}

export function Contacts(props) {
  const { projectId } = useOutletContext();

  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();

  const fetchContacts = async (projectId) => {
    try {
      const response = await getContacts({ projectId });
      const data = response.data;
      setContacts(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchContacts(projectId);
    }
  }, [projectId]);

  return (
   <Contact contacts={contacts} fetchContacts={fetchContacts} projectId={projectId} />
  );
}
