import { useOutletContext } from "react-router-dom";
import { IntegrationShell } from "../common/Shell";

import Messenger from "V2.0/HomePages/HomeIntegrations/Messenger";

export function MessengerIntegration() {
  const { projectId } = useOutletContext();

  return (
    <IntegrationShell>
      <Messenger projectId={projectId} />
    </IntegrationShell>
  );
}
