import React, { useState, useEffect } from "react";
import { Button } from "../../common/button";
import timezoneData from "../../constants/timezones";
import * as projectService from "../../services/projectService";
import { message } from "antd";
import Followup, { useWhatsappTemplates } from "./Followup";
import { Divider } from "../../common/divider";
import { Badge } from "../../common/badge";
import { HomeIcon } from "@heroicons/react/20/solid";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogDescription,
} from "V2.0/common/dialog";
import TabsWithBadge from "V2.0/common/tabsWithBadge";

const CONFIGURATION = {
  BUSINESS_HOURS: "Business Hours",
  FOLLOW_UPS: "Follow Up",
};

function Schedule() {
  const { projects, activeProject, settings, fetchSettings } =
    useOutletContext();
  const navigate = useNavigate();
  const { messageTemplates, fetchTemplates } = useWhatsappTemplates();

  const [followMessageEnabled, setFollowMessageEnabled] = useState(
    settings?.campaignSettings.followUpEnabled || false
  );
  const [followUpSettings, setFollowUpSettings] = useState(
    settings?.campaignSettings.followUpSettings || []
  );

  const [timezone, setTimezone] = useState(
    settings?.campaignSettings.campaignTimeZone || ""
  );
  const [daySchedule, setDaySchedule] = useState(
    settings?.campaignSettings.scheduleData || {}
  );

  const [loading, setLoading] = useState(false);
  const [itemIndexForEdit, setItemIndexForEdit] = useState("");
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("add");
  const [openDelete, setOpenDelete] = useState({
    isOpen: false,
    item: undefined,
  });
  const [activeTab, setActiveTab] = useState(CONFIGURATION.BUSINESS_HOURS);

  const tabs = Object.values(CONFIGURATION).map((tab) => ({
    name: tab,
    current: tab === activeTab,
    href: "#",
  }));

  useEffect(() => {
    const projectId = projects[activeProject]?._id;

    if (projectId) {
      fetchTemplates(projectId);
    }
  }, [projects, activeProject]);

  const handleFollowUpEnableChange = () => {
    setFollowMessageEnabled((prevState) => !prevState);
  };

  const handleDayCheckboxChange = (day) => {
    setDaySchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: { ...prevSchedule[day], checked: !prevSchedule[day].checked },
    }));
  };

  const handleTimeChange = (day, timeType, value) => {
    setDaySchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: { ...prevSchedule[day], [timeType]: value },
    }));
  };

  const handleTimezoneChange = (event) => {
    setTimezone(event.target.value);
  };

  const days = Object.keys(daySchedule);

  const handleSave = async (newFollowUpSettings = followUpSettings) => {
    setLoading(true);

    let newData = {
      scheduleData: daySchedule,
      followUpEnabled: followMessageEnabled,
      followUpSettings: newFollowUpSettings,
      campaignTimeZone: timezone,
    };

    try {
      const response = await projectService.updateProject(
        projects[activeProject]._id,
        { campaignSettings: newData }
      );
      if (response.status === 200) {
        message.success("Changes saved");
        setLoading(false);
        fetchSettings(projects[activeProject]?._id);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);
        message.error(ex.response.data.msg);
      } else {
        setLoading(false);
        console.error("An unexpected error occurred:", ex);
      }
    }
  };

  const handleCreateFollow = () => {
    setOpen(!open);
    setMode("add");
  };

  const truncateMessage = (message, maxLength = 50) => {
    if (message.length <= maxLength) return message;
    return message.slice(0, maxLength) + "...";
  };

  const handleEdit = (index) => {
    setMode("edit");
    setItemIndexForEdit(index);
    setOpen(true);
  };

  const handleDelete = async (order) => {
    const updatedFollowUps = followUpSettings
      .toSpliced(order, 1)
      .map((followUp, index) => ({ ...followUp, order: index }));
    setFollowUpSettings(updatedFollowUps);
    handleSave(updatedFollowUps);
    setOpenDelete({ isOpen: false, item: undefined });
  };

  return (
    <div>
      <main className="">
        <div className="w-2/4 mx-auto my-8">
          <div className="mb-4">
            <Button plain onClick={() => navigate("/campaign")}>
              <ArrowLeftIcon /> back
            </Button>
          </div>
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold">Configuration</h3>
          </div>
          <div className="flex flex-col mx-auto my-0">
            <TabsWithBadge
              className="border-b"
              tabs={tabs}
              onTabChange={(e, { tab }) => setActiveTab(tab.name)}
            />
            <div className="border rounded-lg my-4 p-4">
              <form>
                {activeTab === "Business Hours" && (
                  <>
                    <div className="pb-6 mb-6 border-b border-gray-200 dark:border-gray-700">
                      <label
                        htmlFor="timezones"
                        className="flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        <span className="me-1">Select a timezone</span>
                        <button
                          type="button"
                          data-tooltip-target="tooltip-timezone"
                        >
                          <svg
                            aria-hidden="true"
                            className="w-4 h-4 text-gray-400 cursor-pointer hover:text-gray-900 dark:hover:text-white dark:text-gray-500"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="sr-only">Details</span>
                        </button>
                        <div
                          id="tooltip-timezone"
                          role="tooltip"
                          className="inline-block absolute invisible z-10 py-2 px-3 max-w-sm text-xs font-normal text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                        >
                          Select a timezone that fits your location to
                          accurately display time-related information.
                          <div
                            className="tooltip-arrow"
                            data-popper-arrow
                          ></div>
                        </div>
                      </label>
                      <select
                        id="timezones"
                        name="timezone"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={timezone}
                        onChange={handleTimezoneChange}
                        required
                      >
                        <option value="">Choose a timezone</option>
                        {timezoneData.map((timezone) => (
                          <option key={timezone} value={timezone}>
                            {timezone}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-12">
                      {days.map((day) => (
                        <div className="mb-6" key={day}>
                          <div className="flex items-center justify-between">
                            <div className="flex items-center min-w-[4rem]">
                              <input
                                checked={daySchedule[day].checked}
                                id={day}
                                name="days"
                                type="checkbox"
                                value={day}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                onChange={() => handleDayCheckboxChange(day)}
                              />
                              <label
                                htmlFor={day}
                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                {day.charAt(0).toUpperCase() +
                                  day.slice(1).substring(0, 2)}
                              </label>
                            </div>
                            <div className="w-full max-w-[7rem]">
                              <label
                                htmlFor={`start-time-${day}`}
                                className="sr-only"
                              >
                                Start time:
                              </label>
                              <div className="relative">
                                <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                                  <svg
                                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </div>
                                <input
                                  type="time"
                                  id={`start-time-${day}`}
                                  name={`start-time-${day}`}
                                  className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  min="09:00"
                                  max="18:00"
                                  value={daySchedule[day].startTime}
                                  onChange={(e) =>
                                    handleTimeChange(
                                      day,
                                      "startTime",
                                      e.target.value
                                    )
                                  }
                                  required
                                />
                              </div>
                            </div>
                            <div className="w-full max-w-[7rem]">
                              <label
                                htmlFor={`end-time-${day}`}
                                className="sr-only"
                              >
                                End time:
                              </label>
                              <div className="relative">
                                <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                                  <svg
                                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </div>
                                <input
                                  type="time"
                                  id={`end-time-${day}`}
                                  name={`end-time-${day}`}
                                  className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  min="09:00"
                                  max="18:00"
                                  value={daySchedule[day].endTime}
                                  onChange={(e) =>
                                    handleTimeChange(
                                      day,
                                      "endTime",
                                      e.target.value
                                    )
                                  }
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {activeTab === "Follow Up" && (
                  <div className="flex flex-col gap-y-8 mb-4">
                    <div className="flex flex-col gap-y-2">
                      <div className="flex justify-between items-center gap-x-2">
                        <span className="text-gray-900 dark:text-white text-base font-medium">
                          Follow Up
                        </span>
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value=""
                            name="business-hours"
                            className="sr-only peer"
                            checked={followMessageEnabled}
                            onChange={handleFollowUpEnableChange}
                          />
                          <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          <span className="sr-only">Business hours</span>
                        </label>
                      </div>
                      <p className="text-sm text-gray-500 dark:text-gray-400 font-normal">
                        Enable to follow up with customers
                      </p>
                    </div>
                    {followMessageEnabled && (
                      <div className="flex flex-col">
                        <div className="flex justify-between items-center">
                          <span className="text-gray-900 dark:text-white text-base font-medium">
                            Create follow-up
                          </span>
                          <div className="inline-flex items-center cursor-pointer">
                            <Button onClick={handleCreateFollow} outline>
                              Create
                            </Button>
                          </div>
                        </div>
                        <p className="text-sm text-gray-500 dark:text-gray-400 font-normal">
                          Create a follow up message and configure its timing.
                        </p>

                        {followUpSettings.map((setting, index) => (
                          <div key={setting._id}>
                            {index > 0 && <Divider className="my-2" />}
                            <div className="flex justify-between items-center mt-2">
                              <div className="p-4">
                                <span className="text-gray-700 dark:text-gray-300">
                                  Follow-up {index + 1}
                                </span>
                                <Badge color="sky" className="m-3" href="#">
                                  Time delay : {setting.followUpDelay}
                                </Badge>
                                <p className="text-sm text-gray-500 dark:text-gray-400 font-normal">
                                  {truncateMessage(setting.followUpMessage)}
                                </p>
                              </div>
                              <div>
                                <Button
                                  outline
                                  className="ml-4"
                                  onClick={() => handleEdit(index)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  outline
                                  className="ml-4"
                                  onClick={() => {
                                    setOpenDelete({
                                      isOpen: true,
                                      item: setting,
                                    });
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </form>
              <div className="flex justify-end">
                <Button onClick={() => handleSave()}>Save Configuration</Button>
              </div>
            </div>
          </div>
        </div>
      </main>
      {open && (
        <Followup
          mode={mode}
          messageTemplates={messageTemplates}
          followUpSettings={followUpSettings}
          setFollowUpSettings={setFollowUpSettings}
          itemIndexForEdit={itemIndexForEdit}
          setItemIndexForEdit={setItemIndexForEdit}
          handleSave={handleSave}
          open={open}
          setOpen={setOpen}
          projectId={projects[activeProject]?._id}
        />
      )}
      {openDelete.isOpen && (
        <Dialog
          open={openDelete.isOpen}
          onClose={() => setOpenDelete({ isOpen: false, item: undefined })}
        >
          <DialogTitle>{`Delete Follow-up ${
            openDelete.item.order + 1
          }?`}</DialogTitle>
          <DialogDescription></DialogDescription>
          <DialogActions>
            <Button
              outline
              onClick={() => setOpenDelete({ isOpen: false, item: null })}
            >
              Cancel
            </Button>
            <Button onClick={() => handleDelete(openDelete.item.order)}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default Schedule;
