import React, { useState } from "react";
import { Button } from "../common/button";
import zohoCRM from "../images/zohoCRM.svg";
import GHL from "../images/GHL.png";
import Twilio from "../images/twilio.svg";
import ZapierImage from "../images/zapier.svg";
import calendly from "../images/calendly.svg";
import whatsapp from "../images/whatsapp.svg";
import messenger from "../images/messenger.svg";
import googleCalendarImage from "../images/googleCalendar.svg";
import onceHubImage from "../images/onceHub.svg";
import ZohoCRMComponent from "./HomeIntegrations/ZohoCRM";
import GHLComponent from "../HomePages/HomeIntegrations/GHL";
import EmptyState from "./HomeIntegrations/EmptyState";
import TwilioComponent from "./HomeIntegrations/TwilioComponent";
import ZapierComponent from "./HomeIntegrations/Zapier";
import OnceHub from "./HomeIntegrations/OnceHub";
import Calendly from "./HomeIntegrations/Calendly";
import GoogleCalendar from "./HomeIntegrations/GoogleCalendar";
import Whatsapp from "./HomeIntegrations/Whatsapp";
import Messenger from "./HomeIntegrations/Messenger";
import { useOutletContext } from "react-router-dom";


// Reusable IntegrationItem component
const IntegrationItem = ({ integration, activeItem, onItemClick }) => (
  <div className="bg-white mt-10">
    <span
      className={`flex justify-between items-center border border-gray-300 p-3 rounded-xl cursor-pointer mb-2 ${
        activeItem === integration.id ? "bg-blue-500 text-white" : "bg-white"
      } text-black`}
      onClick={() => onItemClick(integration.id)}
    >
      <img style={{ height: integration.height}} src={integration.logo}></img>
      <span>{integration.name}</span>
    </span>
  </div>
);

// Configuration of integrations
const integrations = [
  {
    id: "ZohoCRM",
    name: "Zoho CRM",
    logo: zohoCRM,
    height:"20px",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam.",
  },
  {
    id: "GHL",
    name: "GoHighLevel Account",
    logo: GHL,
    height:"20px",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam.",
  },
  {
    id: "Twilio",
    name: "Twilio",
    logo: Twilio,
    height:"20px",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam.",
  },
  {
    id: "Zapier",
    name: "Zapier",
    logo: ZapierImage,
    height:"20px",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam.",
  },
  {
    id: "Whatsapp",
    name: "Whatsapp",
    logo: whatsapp,
    height:"20px",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam.",
  },
  {
    id: "Messenger",
    name: "Messenger",
    logo: messenger,
    height:"20px",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam.",
  },
  // {
  //   id: "GoogleCalendar",
  //   name: "Google Calendar",
  //   logo: googleCalendarImage,
  //   height:"25px",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam.",
  // },
  // {
  //   id: "Calendly",
  //   name: "Calendly",
  //   logo: calendly,
  //   height:"15px",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam.",
  // },
  // {
  //   id: "Oncehub",
  //   name: "Oncehub",
  //   logo: onceHubImage,
  //   height:"15px",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam.",
  // },
];

function HomeIntegrations() {
  const { projects, activeProject} = useOutletContext();

  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  return (
    <div className="bg-slate-50">
      <main className="flex justify-center items-start min-h-screen">
    {activeItem==="ZohoCRM" && <ZohoCRMComponent  projects={projects} activeProject={activeProject}/>}
    {activeItem==="GHL" && <GHLComponent  projects={projects} activeProject={activeProject}/>}
    {activeItem==="Twilio" && <TwilioComponent  projects={projects} activeProject={activeProject}/>}
    {activeItem==="Zapier" && <ZapierComponent  projects={projects} activeProject={activeProject}/>}
    {activeItem==="Whatsapp" && <Whatsapp  projects={projects} activeProject={activeProject}/>}
    {activeItem==="Messenger" && <Messenger  projects={projects} activeProject={activeProject}/>}
    {activeItem==="Oncehub" && <OnceHub  projects={projects} activeProject={activeProject}/>}
    {activeItem==="GoogleCalendar" && <GoogleCalendar projects={projects} activeProject={activeProject}/>}
    {activeItem==="Calendly" && <Calendly  projects={projects} activeProject={activeProject}/>}


    {activeItem===null && <EmptyState/>}

      </main>

      <aside className="fixed inset-y-0 left-72 hidden w-96 overflow-y-auto border-r border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block mt-10">
        {integrations.map((integration) => (
          <IntegrationItem
            key={integration.id}
            integration={integration}
            activeItem={activeItem}
            onItemClick={handleItemClick}
          />
        ))}
      </aside>
    </div>
  );
}

export default HomeIntegrations;
