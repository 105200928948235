import { useState } from "react";

import TabsWithBadge from "V2.0/common/tabsWithBadge";

import { WhatsappPhoneNumbers } from "./Whatsapp_phone_numbers";
import { WhatsappTemplates } from "./Whatsapp_templates";

const WHATSAPP_TABS = {
  PHONE_NUMBERS: "Phone numbers",
  TEMPLATES: "Templates",
};

const WhatsappTabs = {
  [WHATSAPP_TABS.PHONE_NUMBERS]: (props) => <WhatsappPhoneNumbers {...props} />,
  [WHATSAPP_TABS.TEMPLATES]: (props) => <WhatsappTemplates {...props} />,
};

export function WhatsappManager(props) {
  const { existingConfiguration, projectId, fetchWhatsappConfiguration } =
    props;
  const [activeTab, setActiveTab] = useState(WHATSAPP_TABS.PHONE_NUMBERS);

  const tabs = Object.values(WHATSAPP_TABS).map((tab) => ({
    name: tab,
    current: tab === activeTab,
    href: "#",
  }));

  return (
    <div className="flex flex-col">
      <TabsWithBadge
        className="border-b px-8"
        tabs={tabs}
        onTabChange={(e, { tab, index }) => setActiveTab(tab.name)}
      />
      <div className="p-4">
        {WhatsappTabs[activeTab]({
          existingConfiguration,
          projectId,
          fetchWhatsappConfiguration,
        })}
      </div>
    </div>
  );
}
