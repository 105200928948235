import httpService from "../../../services/httpService";

export function useWhatsappSend() {
  const sendWhatsappMessage = async (projectId, data, onSuccess) => {
    try {
      const response = await httpService.post(
        `/whatsapp/manage/addToWhatsappThread/${projectId}`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        onSuccess();
        console.log("success");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { sendWhatsappMessage };
}
