import { useState } from "react";

import { Button } from "V2.0/common/button";
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle,
} from "V2.0/common/dialog";
import httpService from "V2.0/services/httpService";
import { message } from "antd";

export function ResolveConversation(props) {
  const { projectId, conversationId, fetchConversations, resetSelectedConversation } = props;
  const [isOpen, setIsOpen] = useState(false);

  const resolveConversation = async (projectId, conversationId) => {
    try {
      const response = await httpService.put(
        `/conversation/removeHumanAgentAssignment/${projectId}/${conversationId}`
      );

      if (response.status === 200) {
        fetchConversations(1, 50);
        resetSelectedConversation();
        message.success("Resolved");
        setIsOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button outline onClick={() => setIsOpen(true)}>Resolve Conversation</Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Resolve Conversation</DialogTitle>
        <DialogDescription>
          By resolving, you're reassigning the conversation back to AI. Do you want to
          resolve?
        </DialogDescription>
        <DialogActions>
          <Button outline onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button
            onClick={() => resolveConversation(projectId, conversationId)}
          >
            Yes, Resolve
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
