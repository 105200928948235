import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import { Button } from "../../common/button";
import * as voiceapiservice from "../../services/voiceAPIService";
import { message } from "antd";
import axios from "axios";

function TestPhoneCall({ projects, activeProject, settings }) {
  const [reload, setReload] = useState(false);

  const [inputField, setInputField] = useState({
    toNumber:  "",
  });

  let voiceURL = process.env.REACT_APP_VOICE_API;
  const jwt = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
        name: "toNumber",
        label: "Call Number",
        description: "Always start your number with the country code. Ensure that your Twilio account is connected before placing a call.",
        type: "text",
        placeholder: "+1123456789"
    }
    
  ];

  const buttonInfo = { label: "Send Demo call", style: "right-side" };

  const schema = {
    toNumber: Joi.string().allow(null, "").label("To Number"),

  };

 

  const handleCall = async (data) => {
 
    try {
      const response = await voiceapiservice.placeCall(
        projects[activeProject]._id,
        data
      );
      if (response.status === 200) {
        message.success("We have made the call. You will receive it soon.");
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false); // Ensure setLoading is defined and used correctly
        message.error(ex.response.data.msg);
        setLoading(false);
      }
    }
  };



  return (
    <div>
      <Form
        key={JSON.stringify(inputField)}
        errorMessage={errorMessage}
        inputField={inputField}
        template={template}
        schema={schema}
        onSubmit={handleCall}
        loading={loading}
        setLoading={setLoading}
        // getValue ={getInputValue}
        buttonInfo={buttonInfo}
      />
    </div>
  );
}

export default TestPhoneCall;
