import React, { useState } from "react";

import { message } from "antd";
import * as projectService from "V2.0/services/projectService";
import { Switch } from "V2.0/common/switch";

function EnableAgentTransfer({ projectId, settings, refetchSettings }) {
  const [humanAgentEnabled, setHumanAgentEnabled] = useState(
    settings?.voiceChatSettings?.callTransferEnabled ?? false
  );

  const handleToggle = async () => {
    setHumanAgentEnabled(!humanAgentEnabled);

    let data = {
      callTransferEnabled: !humanAgentEnabled,
    };

    const response = await projectService.updateProject(projectId, {
      voiceChatSettings: data,
    });
    if (response.status === 200) {
      message.success("Changes saved");
      refetchSettings(projectId);
    }
  };

  return (
    <Switch
      color="emerald"
      id={`checkbox-${humanAgentEnabled}`}
      checked={humanAgentEnabled}
      onClick={handleToggle}
    />
  );
}

export default EnableAgentTransfer;
