import React, { useState } from "react";
import { message } from "antd";
import * as projectService from "../../../services/projectService";
import ExtractionFields from "./ExtractionFields";
import { AddNewFieldDialog, NewFields } from "./NewFields";

import ExtractionIcon from "../../../images/extraction.svg";
import TabsWithBadge from "../../../common/tabsWithBadge";
import { PlusIcon } from "@heroicons/react/24/solid";
import { Button } from "../../../common/button";

import { ExtractionsDownloader } from "../../Downloads/index";
import { ArrowDownCircleIcon } from "@heroicons/react/24/outline";

function useExtractions(projectId) {
  const [extraction, setExtractions] = useState([]);

  const fetchExtractions = async (projectId) => {
    try {
      const responseData = await projectService.getExtraction(projectId);

      if (responseData.status === 200) {
        setExtractions(responseData.data);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data);
        return;
      }
    }
  };

  return {
    extraction,
    fetchExtractions,
  };
}

const TABS = {
  EXTRACTION_FIELDS: "Extraction Fields",
  NEW_EXTRACTION: "New Extraction",
};

const Tabs = {
  [TABS.EXTRACTION_FIELDS]: (props) => <ExtractionFields {...props} />,
  [TABS.NEW_EXTRACTION]: (props) => <NewFields {...props} />,
};

function ParentExtractionModule({ projects, activeProject }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);

  const [newExtractions, setNewExtractions] = useState([]);

  const { extraction, fetchExtractions } = useExtractions();

  const [tabState, setTabState] = useState(TABS.EXTRACTION_FIELDS);

  const tabs = Object.values(TABS).reduce((acc, tab) => {
    return [...acc, { name: tab, href: "#", current: tab === tabState }];
  }, []);

  return (
    <div className="w-full flex flex-col items-center justify-center p-8">
      <main className="w-full">
        <div className="flex flex-col gap-y-4">
          <div className="flex justify-between items-center">
            <div className="flex gap-x-2">
              {/* <img src={ExtractionIcon} alt="Extraction icon" width={20} /> */}
              <p className="font-semibold text-lg">Extraction</p>
            </div>
            <div className="flex gap-x-2">
              <Button
                onClick={() => {
                  setIsOpen(true);
                  setTabState(TABS.NEW_EXTRACTION)
                }}
              >
                <PlusIcon /> Add Extraction
              </Button>
              <Button outline onClick={() => setIsDownloadOpen(true)}>
                <ArrowDownCircleIcon />
                Download
              </Button>
            </div>
          </div>

          <TabsWithBadge
            className="border-b"
            tabs={tabs}
            onTabChange={(e, { tab }) => setTabState(tab.name)}
          />
          <div>
            {Tabs[tabState]({
              projects,
              activeProject,
              extraction,
              fetchExtractions,
              newExtractions,
              setIsOpen,
              setNewExtractions,
              onSubmit: () => setTabState(TABS.EXTRACTION_FIELDS),
            })}
          </div>
        </div>
      </main>
      {isDownloadOpen && (
        <ExtractionsDownloader
          isOpen={isDownloadOpen}
          onClose={() => setIsDownloadOpen(false)}
          projectId={projects[activeProject]._id}
        />
      )}
      <AddNewFieldDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        setNewExtractions={setNewExtractions}
        onAddNewExtractions={(extraction) => {
          setNewExtractions((prevExtraction) => [
            ...prevExtraction,
            extraction,
          ]);
          setTabState(TABS.NEW_EXTRACTION);
        }}
      />
    </div>
  );
}

export default ParentExtractionModule;
