import httpService from "V2.0/services/httpService";

function toUint8Array(base64String, atobFn) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = atobFn(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}

async function registerServiceWorker() {
  if (!("serviceWorker" in navigator)) {
    return;
  }

  if (!("PushManager" in window)) {
    return;
  }

  if ("Notification" in window) {
    if (
      Notification.permission === "denied" ||
      Notification.permission === "granted"
    ) {
      return;
    }

    try {
      const permission = await Notification.requestPermission();

      if (permission === "granted") {
        return navigator.serviceWorker.register("/sw.js");
      } else if (permission === "denied") {
        console.log("Notification permission denied.");
      } else {
        console.log("Notification permission request was dismissed.");
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  }
}

async function sendRegistrationToServer(subscription, projectId, userId) {
  try {
    if (!projectId) {
      return;
    }

    const response = await httpService.post(
      `/pushSubscription/subscribe/${projectId}/`,
      JSON.stringify({
        subscription,
        projectId,
        userId,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
}

async function createSubscription(serviceWorkerRegistration) {
  const subscription = await serviceWorkerRegistration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: toUint8Array(
      process.env.REACT_APP_PUBLIC_VAPID_KEY,
      atob
    ),
  });

  return subscription;
}

async function manageSubscription(projectId, userId) {
  try {
    const registration = await registerServiceWorker();

    if (!registration) {
      return;
    }
    
    const existingSubscription =
      await registration.pushManager.getSubscription();

    if (!existingSubscription) {
      const serviceWorkerRegistration = await navigator.serviceWorker.ready;
      const createdSubscription = await createSubscription(
        serviceWorkerRegistration
      );
      sendRegistrationToServer(createdSubscription, projectId, userId);
    }
  } catch (error) {
    console.error(error);
  }
}

export { manageSubscription };
