import React from "react";
import InputComponent from "../../../../../components/InputComponent.jsx";

function Form({ data, error, handleChange, template }) {
  return (
    <div className="flex flex-col basis:full gap-8">
      {template.map((item, index) => {
        const {
          name,
          label,
          placeholder,
          style,
          options,
          arrayLength,
          description,
          min,
          max,
          defaultValue,
          step,
          type,
        } = item;

        return (
          <div className={style?.className}>
            <InputComponent
              key={index}
              name={name}
              value={data[name]}
              label={label}
              type={type}
              placeholder={placeholder}
              onChange={handleChange}
              error={error[name]}
              style={style}
              options={options}
              arrayLength={arrayLength}
              description={description}
              min={min}
              max={max}
              defaultValue={defaultValue}
              step={step}
            />
          </div>
        );
      })}
    </div>
  );
}

export default Form;
