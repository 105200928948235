import React, { useState } from "react";
import { message } from "antd";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as knowledgebaseService from "../../services/knowledgebaseService";

function AddSpreadsheet({ projects, activeProject, onClose, refetch }) {
  const [inputField] = useState({
    url: "",
    template: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "url",
      label: "Google Sheet link",
      description: `Add 'superdash@superdash-382709.iam.gserviceaccount.com' as 'viewer' to the Google Sheet.`,
      type: "text",
    },
    {
      name: "template",
      label: "Sheet Template",
      type: "select",
      options: [
        { id: 0, value: "", label: "No option selected" },
        { id: 3, value: "general", label: "Table Data" },
        { id: 1, value: "qna", label: "Qna Template" },
        { id: 2, value: "url", label: "URL Template" },
        // { id: 2, value: "2", label: "cust" },
      ],
    },
  ];

  const buttonInfo = { label: "Upload data", style: "entire-width" };
  // const buttonInfo1 = { label: " Create A", style: "" };

  const schema = {
    url: Joi.string().uri().min(2).max(500).required().label("URL"),
    template: Joi.string().max(50).required().label("Template"),
  };

  const handleSubmit = async (data) => {
    {
      try {
        const response = await knowledgebaseService.addGSheet(
          projects[activeProject]._id,
          data
        );
        if (response.status === 200) {
          message.success("Success. Your file has now been queued.")
            setLoading(false);
            refetch(projects[activeProject]._id);
            onClose();

        }
      } catch (ex) {
        if (
          ex.response &&
          ex.response.status >= 400 &&
          ex.response.status < 500
        ) {
          setLoading(false);
          message.error(ex.response.data.msg);
          return;
        }
      }
    }
  };

  const handleTemplate = (string) => {
    if (string === "1")
      window.open(
        "https://docs.google.com/spreadsheets/d/1Il3HA1afnwwCDYkGqCz8NTIlqDPfPVtOfzR6sAHuCeE/edit?usp=sharing",
        "_blank"
      );

    if (string === "2")
      window.open(
        "https://docs.google.com/spreadsheets/d/1iP-GE4V4ubNZUd2x2WGMD9LuAJXu5bdjtyjq8bpMTVc/edit#gid=0",
        "_blank"
      );
  };

  return (
    <Form
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={buttonInfo}
      onSubmit={handleSubmit}
      loading={loading}
      setLoading={setLoading}
    />
  );
}

export default AddSpreadsheet;
