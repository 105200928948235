import React, { useState } from "react";
import { Button } from "V2.0/common/button";
import { PlusIcon, XMarkIcon } from "@heroicons/react/16/solid";
import { message } from "antd";
import { Dialog, DialogBody, DialogTitle } from "V2.0/common/dialog";
import { addNewAgentButtons, AgentForm } from "../../../common/Agent_form";
import { phoneAgentFormTemplate } from "../../../models/template";
import { phoneAgentFormSchema } from "../../../models/schema";
import { useAddHumanAgent } from "../../../models/useAddHumanAgent";

export function AddAgent({ projectId, refetchAgents }) {
  const [open, setOpen] = useState(false);
  const [agent] = useState({
    name: "",
    description: "",
    phoneNumber: "",
    searchTerm: "",
    contactMedium: "phone",
  });
  const { loading, setLoading, handleAddHumanAgent } = useAddHumanAgent();

  function handleSubmit(data) {
    const onSuccess = () => {
      refetchAgents();
      message.success("Agent added");
      setOpen(false);
    };

    const onError = () => {
      message.error("Failed to add agent");
      setOpen(false);
    };
    handleAddHumanAgent({ projectId, data, onSuccess, onError });
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <PlusIcon />
        New Human Agent
      </Button>
      <Dialog
        className=""
        open={open}
        onClose={() => setOpen(false)}
        size="2xl"
      >
        <DialogTitle>
          <div class="text-lg flex flex-row justify-between">
            <div>New Human Agent</div>
            <div class="text-lg">
              <Button plain onClick={() => setOpen(false)}>
                <XMarkIcon />
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogBody>
          <AgentForm
            agent={agent}
            loading={loading}
            setLoading={setLoading}
            projectId={projectId}
            refetchAgents={refetchAgents}
            onCancel={() => setOpen(false)}
            onSubmit={handleSubmit}
            buttons={addNewAgentButtons}
            agentFormTemplate={phoneAgentFormTemplate}
            agentFormSchema={phoneAgentFormSchema}
          />
        </DialogBody>
      </Dialog>
    </>
  );
}
