import React from "react";
import InputComponent from "V2.0/components/InputComponent";
import { Button } from "V2.0/common/button";

export function ShowOriginalCallerID({ value, onToggle, onSave }) {
  return (
    <div className="flex flex-col">
      <InputComponent
        name="Show Original caller ID"
        label="Show Original Caller ID"
        value={value}
        onChange={onToggle}
        type="toggle"
        description="Show caller's number on call transfer. If switched off, the Twilio number associated with the workspace will be shown"
      />
      <Button color="zinc" onClick={onSave} className="self-end">Save</Button>
    </div>
  );
}
