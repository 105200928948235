import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import auth from "../services/authService";

function Logout(props) {
  const navigate = useNavigate();

  useEffect(() => {

    localStorage.removeItem('activeProject');
    auth.logout();
    navigate("/login");
  }, []); 

  return null; 
}

export default Logout;
