import { useState, useEffect } from "react";
import Joi from "joi-browser";
import { message } from "antd";

import Form from "V2.0/components/Form";

import { updateProject } from "V2.0/services/projectService";

const InactivitySettingsModel = {
  inactivityMessageEnabled: {
    template: {
      label: "Speak Inactive Message",
      description: "Speak the message, after wait duration has elapsed",
      type: "toggle",
    },
    schema: Joi.boolean(),
  },
  inactivityTime: {
    template: {
      label: "Inactive Wait Duration",
      description: "In seconds, to wait before speaking inactive message",
      type: "number",
    },
    schema: Joi.number(),
  },
  inactivityMessage: {
    template: {
      label: "Inactive Message",
      description: "Message to be said, after inactive duration has elapsed",
      type: "text",
    },
    schema: Joi.string().allow(null, ""),
  },
  callEndOnInactivityEnabled: {
    template: {
      label: "End Call",
      description: "End the call, after wait duration has elapsed",
      type: "toggle",
    },
    schema: Joi.boolean(),
  },
  callEndTime: {
    template: {
      label: "End Call Wait Duration",
      description: "In seconds, to wait before ending the call",
      type: "number",
    },
    schema: Joi.number(),
  },
};

const inactivitySettingsFields = Object.keys(InactivitySettingsModel).map(
  (field) => {
    const template = InactivitySettingsModel[field].template;
    return {
      ...template,
      name: field,
    };
  }
);
const inactivitySettingsSchema = Object.keys(InactivitySettingsModel).reduce(
  (acc, field) => {
    const schema = InactivitySettingsModel[field].schema;
    return {
      ...acc,
      [field]: schema,
    };
  },
  {}
);

export function InactivitySettings(props) {
  const { settings, fetchSettings, projectId, onClose } = props;

  const [inactivitySettings, setInactivitySettings] = useState({
    callEndOnInactivityEnabled: false,
    callEndTime: 0,
    inactivityMessage: "",
    inactivityMessageEnabled: false,
    inactivityTime: 0,
  });

  useEffect(() => {
    const { _id, ...restInactivitySettings } =
      settings?.voiceChatSettings.inactivitySettings;
    setInactivitySettings(restInactivitySettings);
  }, [settings]);

  const [loading, setLoading] = useState(false);

  const handleSave = async (data) => {
    const payload = {
      voiceChatSettings: {
        inactivitySettings: data,
      },
    };

    try {
      const response = await updateProject(projectId, payload);

      if (response.status === 200) {
        fetchSettings(projectId);
        message.success("Settings updated");
        onClose();
      }
    } catch (error) {
      message.error("Failed to update settings");
      console.error(error);
    }
  };

  return (
    <Form
      inputField={inactivitySettings}
      template={inactivitySettingsFields}
      schema={inactivitySettingsSchema}
      onSubmit={handleSave}
      loading={loading}
      setLoading={setLoading}
      buttonInfo={{ label: "Save", style: "right-side" }}
    />
  );
}
