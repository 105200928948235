import TwilioComponent from "V2.0/HomePages/HomeIntegrations/TwilioComponent";
import { IntegrationShell } from "../common/Shell";
import { useOutletContext } from "react-router-dom";
import Twilio from "V2.0/images/twilio.svg";


export function TwilioIntegration(props) {
  const { projectId } = useOutletContext();
  return (
    <IntegrationShell>
      <TwilioComponent projectId={projectId} />
    </IntegrationShell>
  );
}
