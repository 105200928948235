import { useState } from "react";
import { updateHumanAgent } from "V2.0/services/humanAgentService";

export function useUpdateHumanAgent() {
  const [state, setState] = useState({
    loading: false,
  });

  const handleUpdateHumanAgent = async ({
    projectId,
    agentId,
    data,
    onSuccess,
    onError,
  }) => {
    setState({
      loading: true,
    });
    try {
      const response = await updateHumanAgent(projectId, agentId, data);

      if (response.status === 200) {
        setState({ loading: false });
        onSuccess();
      }
    } catch (error) {
      setState({ loading: false });
      console.error(error);
      onError();
    }
  };

  return {
    loading: state.loading,
    setLoading: (state) => setState(state),
    handleUpdateHumanAgent,
  };
}
