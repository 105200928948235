import http from "./httpService";
const apiEndpoint = "/whatsapp";

function manageWhatsapp(projectId, req) {
  return http.post(apiEndpoint + "/manage/register/" + projectId, req);
}

function getWhatsAppConfig(projectId) {
  return http.get(`${apiEndpoint}/manage/config/${projectId}`);
}

function deleteWhatsAppConfig(projectId) {
  return http.delete(`${apiEndpoint}/manage/deleteConfig/${projectId}`);
}

export { manageWhatsapp, getWhatsAppConfig, deleteWhatsAppConfig };
