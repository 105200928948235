import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common/table";
import { Button } from "../../../common/button";

import { PlusCircleIcon, TrashIcon } from "@heroicons/react/16/solid";

import * as projectService from "../../../services/projectService";
import { message } from "antd";

function TableConfirmation({
  extraction,
  confirmExtraction,
  setConfirmExtraction,
  projects,
  activeProject,
  onAddAnotherField,
  refetchExtractions,
}) {
  const [loading, setLoading] = useState(false);

  const MAX_LENGTH = 50;

  // Function to truncate the text
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  const handleDeleteConfirmation = (index) => {
    setConfirmExtraction((prevConfirmExtraction) =>
      prevConfirmExtraction.filter((_, i) => i !== index)
    );
  };

  const handleConfirmation = async () => {
    setLoading(true);
    let data = {};

    data.extractionParameters = [
      ...extraction.extraction.extractionParameters,
      ...confirmExtraction,
    ];

    try {
      const response = await projectService.createExtraction(
        projects[activeProject]._id,
        data
      );

      if (response.status === 200) {
        setLoading(false);
        message.success("Fields added");
        setConfirmExtraction([]);
        refetchExtractions();
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
      setLoading(false);
    }
  };

  return (
    <div>
      {/* <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          Confirm new fields to be added for extraction
        </div> */}
      <div className="min-h-[30vh] max-h-[calc(90vh-200px)] overflow-auto">
        <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
          <TableHead>
            <TableRow>
              <TableHeader>Field name</TableHeader>
              <TableHeader>Type</TableHeader>
              <TableHeader>Instruction</TableHeader>
              <TableHeader>Action</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {confirmExtraction.map((item, index) => (
              <TableRow key={index}>
                <TableCell className="font-medium">
                  {item.parameterName}
                </TableCell>
                <TableCell>{item.parameterType}</TableCell>
                <TableCell>
                  {truncateText(item.parameterDescription, MAX_LENGTH)}
                </TableCell>
                <TableCell>
                  <div className="-mx-2 -my-1.5 sm:-mx-2.5">
                    <Button
                      outline
                      onClick={() => handleDeleteConfirmation(index)}
                    >
                      <TrashIcon />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
        {confirmExtraction.length > 0 && (
          <div className="px-4 py-4 sm:px-6 flex justify-end gap-x-4">
            <Button plain onClick={onAddAnotherField} disabled={loading}>
              <PlusCircleIcon />
              Add another field
            </Button>
            <Button loading={loading} onClick={handleConfirmation}>
              Confirm fields
            </Button>
          </div>
        )}
    </div>
  );
}

export default TableConfirmation;
