import { Dialog, DialogBody, DialogTitle } from "V2.0/common/dialog";
import Form from "V2.0/components/Form";
import { DialogTitleWithClose } from "V2.0/HomePages/HomeModule/CustomAPIs/components/Dialog/DialogTitleWithClose";
import { message } from "antd";
import { useMemo, useState } from "react";
import httpService from "V2.0/services/httpService";

import { fieldTemplate, fieldSchema } from "../models/template";

export function EditContact(props) {
  const { fetchContacts, projectId, contact, open, onClose } = props;
  
  const inputFields = useMemo(() => {
      const {
        project,
        dnd,
        company,
        address,
        dndSettings,
        __v,
        _id: contactId,
        createdAt,
        updatedAt,
        ...rest
      } = contact;

    const { _id: addressId, ...restAddress } = address;

    return {
      ...rest,
      ...restAddress,
    };
  }, [contact]);

  const [loading, setLoading] = useState(false);
  const onDialogClose = () => {
    onClose();
    setLoading(false);
  };

  const onSubmit = async (data) => {
    const {
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      country,
      ...rest
    } = data;

    const address = {
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: state,
      postalCode: postalCode,
      country: country,
    };

    const payload = {
      contact: {
        ...rest,
        address,
      },
    };

    try {
      const response = await httpService.put(
        `/contact/editContact/${projectId}/${contact._id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        message.success("Contact updated");
        fetchContacts(projectId);
        onDialogClose();
      }
    } catch (error) {
      if (error.response.status === 409) {
        message.warning("Contact already exists");
        setLoading(false);
      }

      if (error.response.status === 400) {
        message.error("Failed to add contact");
        console.error(error);
      }
    }
  };

  return (
    <>
      {open && (
        <Dialog open={open} onClose={() => {}}>
          <DialogTitle>
            <DialogTitleWithClose
              title="Edit Contact"
              onClose={onDialogClose}
            />
          </DialogTitle>
          <DialogBody>
            <Form
              template={fieldTemplate}
              inputField={inputFields}
              schema={fieldSchema}
              onSubmit={onSubmit}
              handleSecondary={onDialogClose}
              buttonInfo={{
                buttons: "2",
                primaryLabel: "Update",
                secondaryLabel: "Cancel",
              }}
              loading={loading}
              setLoading={setLoading}
              errorMessage={{}}
            />
          </DialogBody>
        </Dialog>
      )}
    </>
  );
}
