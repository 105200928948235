import { useState } from "react";
import {
  ArrowDownCircleIcon,
  DocumentIcon,
  EllipsisHorizontalIcon,
  MusicalNoteIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";
import { Button } from "V2.0/common/button";
import { formatTimestamp } from "..";
import { getMedia } from "./helpers/get_media";
import { generateDownload } from "./helpers/generate_download";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "V2.0/common/dropdown";

const mediaMessageRegEx = /(audio|image|document)::(\d+)/;

function checkForMediaMessage(message) {
  const match = message.match(mediaMessageRegEx);

  if (match) {
    return {
      isMediaMessage: true,
      mediaType: match[1],
      mediaId: match[2],
      message: match[0],
    };
  }

  return {
    isMediaMessage: false,
  };
}

function useMediaMessage({ projectId, conversationId, mediaId }) {
  const [media, setMedia] = useState({
    url: "",
    type: "",
  });
  const [isDownloading, setIsDownloading] = useState(false);

  const handleLoad = () => {
    setIsDownloading(true);
    getMedia({ projectId, conversationId, mediaId })
      .then(({ url, type }) => {
        setMedia({
          url,
          type,
        });
        setIsDownloading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsDownloading(false);
      });
  };

  const handleDirectDownload = () => {
    setIsDownloading(true);
    getMedia({ projectId, conversationId, mediaId })
      .then(({ url, type }) => {
        generateDownload(`${mediaId}.${type}`, url);
        setIsDownloading(false);
      })
      .catch((error) => {
        setIsDownloading(false);
        console.error(error);
      });
  };

  return {
    media,
    isDownloading,
    handleLoad,
    handleDirectDownload,
  };
}

function AudioMessage({
  className,
  projectId,
  conversationId,
  mediaId,
  user,
  timestamp,
}) {
  const { media, isDownloading, handleLoad } = useMediaMessage({
    projectId,
    conversationId,
    mediaId,
  });

  const bubbleColor = user
    ? "bg-blue-700 text-gray-200"
    : "bg-blue-100 text-gray-700";

  return (
    <div className={className}>
      <div className="flex items-end">
        <div
          className={`w-50 max-w-sm md:max-w-md rounded-lg p-4 ${bubbleColor} `}
        >
          {media.url ? (
            <>
              <audio controls>
                <source src={media.url} />
              </audio>
            </>
          ) : (
            <div className="flex items-center justify-between gap-x-4">
              <div className="flex items-center gap-x-2">
                <MusicalNoteIcon className="size-5" /> Audio
              </div>
              <Button
                outline
                className="rounded-lg"
                loading={isDownloading}
                onClick={handleLoad}
              >
                <ArrowDownCircleIcon className="size-8" />
              </Button>
            </div>
          )}
          {timestamp && (
            <div className="flex justify-end mt-2">
              <span className="text-xs text-gray-600">
                {formatTimestamp(timestamp)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function Message({ className, title, onDownload, user, timestamp, loading }) {
  const bubbleColor = user
    ? "bg-blue-700 text-gray-200"
    : "bg-blue-100 text-gray-700";

  return (
    <div className={className}>
      <div className="flex items-end">
        <div
          className={`w-50 max-w-sm md:max-w-md rounded-lg ${bubbleColor} p-4`}
        >
          <div className="flex items-center justify-between gap-x-4">
            <div className="flex items-center gap-x-2">{title}</div>
            <Button
              outline
              className="rounded-lg"
              loading={loading}
              onClick={onDownload}
            >
              <ArrowDownCircleIcon className="size-8" />
            </Button>
          </div>
          {timestamp && (
            <div className="flex justify-end mt-2">
              <span className="text-xs text-gray-600">
                {formatTimestamp(timestamp)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function DocumentMessage({
  className,
  projectId,
  conversationId,
  mediaId,
  user,
  timestamp,
}) {
  const { handleDirectDownload, isDownloading } = useMediaMessage({
    projectId,
    conversationId,
    mediaId,
  });

  return (
    <Message
      title={
        <>
          <DocumentIcon className="size-5" /> Document
        </>
      }
      user={user}
      onDownload={handleDirectDownload}
      timestamp={timestamp}
      className={className}
      loading={isDownloading}
    />
  );
}

function ImageMessage({
  className,
  projectId,
  conversationId,
  mediaId,
  user,
  timestamp,
}) {
  const { media, handleLoad, isDownloading } = useMediaMessage({
    projectId,
    conversationId,
    mediaId,
  });
  const bubbleColor = user
    ? "bg-blue-700 text-gray-200"
    : "bg-blue-100 text-gray-700";

  return (
    <div className={className}>
      <div className="flex items-end">
        <div
          className={`w-50 max-w-sm md:max-w-md rounded-lg p-4 ${bubbleColor}`}
        >
          {media.url ? (
            <div className="w-64 h-64 overflow-hidden">
              <div className="flex justify-end">
                <Dropdown>
                  <DropdownButton plain>
                    <EllipsisHorizontalIcon />
                  </DropdownButton>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() =>
                        generateDownload(`${mediaId}.${media.type}`, media.url)
                      }
                    >
                      Download
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <img src={media.url} className="w-full h-full object-cover" />
            </div>
          ) : (
            <div className="flex items-center justify-between gap-x-4">
              <div className="flex items-center gap-x-2">
                <PhotoIcon className="size-5" /> Image
              </div>
              <Button
                outline
                className="rounded-lg"
                loading={isDownloading}
                onClick={handleLoad}
              >
                <ArrowDownCircleIcon className="size-8" />
              </Button>
            </div>
          )}
          {timestamp && (
            <div className="flex justify-end mt-2">
              <span className="text-xs text-gray-600">
                {formatTimestamp(timestamp)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const MEDIA_MESSAGE_TYPE = {
  AUDIO: "audio",
  IMAGE: "image",
  DOCUMENT: "document",
};

const MediaMessages = {
  [MEDIA_MESSAGE_TYPE.AUDIO]: (props) => <AudioMessage {...props} />,
  [MEDIA_MESSAGE_TYPE.DOCUMENT]: (props) => <DocumentMessage {...props} />,
  [MEDIA_MESSAGE_TYPE.IMAGE]: (props) => <ImageMessage {...props} />,
};

export { MEDIA_MESSAGE_TYPE, MediaMessages, checkForMediaMessage };
