import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as thirdPartyServices from "../../services/thirdpartyService";
import { message } from "antd";
import Twilio from "../../images/twilio.svg";
import { useOutletContext } from "react-router-dom";

function TwilioComponent({ projectId }) {
  const [formFieldforTwilio, setFormFieldForTwilio] = useState({
    twilioAccountSID: "",
    twilioAuthToken: "",
    twilioPhoneNumber: "",
    call: false,
    sms: false,
  });

  const [errorMessage] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (projectId) {
          const response = await thirdPartyServices.getthirdpartyData(
            projectId
          );
          const { twilioAccountSID, twilioAuthToken, twilioPhoneNumber } =
            response.data.telephonySettings.twilioSettings;

          const registerNumber =
            response.data.telephonySettings?.registerNumber || [];
          const twilioSettings = {
            twilioAccountSID,
            twilioAuthToken,
            twilioPhoneNumber,
            call: registerNumber.includes("call"),
            sms: registerNumber.includes("sms"),
          };
          twilioPhoneNumber && setFormFieldForTwilio(twilioSettings);
        }
      } catch (error) {}
    };
    fetchData();
  }, [projectId]);

  const [loading, setLoading] = useState(false);

  const templateTwilio = [
    {
      name: "twilioAccountSID",
      label: "Account SID",
      type: "text",
      style: "",
    },
    {
      name: "twilioAuthToken",
      label: "Auth Token",
      type: "password",
      style: "",
    },
    {
      name: "twilioPhoneNumber",
      label: "Phone number (prefix country code)",
      type: "text",
      style: "",
    },
    {
      name: "call",
      label: "Calls",
      description: "Allow voice agent to manage your calls",
      type: "toggle",
    },
    {
      name: "sms",
      label: "SMS",
      description: "Allow voice agent to manage your sms",
      type: "toggle",
    },
  ];

  const buttonInfoTwilio = {
    label: "Connect Twilio",
    style: "entire-width",
  };

  const schemaTwilio = {
    twilioAccountSID: Joi.string().max(5000).required().label("Account SID"),
    twilioAuthToken: Joi.string().max(5000).required().label("Auth SID"),
    twilioPhoneNumber: Joi.string()
      .regex(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
      .min(6)
      .required()
      .label("Phone number")
      .error((errors) => {
        return errors.map((err) => {
          if (err.type === "string.min") {
            return {
              message: "Phone number must be at least 6 characters long.",
            };
          }
          if (err.type === "string.regex.base") {
            return { message: "Please enter a valid phone number." };
          }
          if (err.type === "any.empty") {
            return { message: "Phone number is required." };
          }
          return err;
        });
      }),
    call: Joi.boolean().label("Calls"),
    sms: Joi.boolean().label("SMS"),
  };

  const handleTwilioConnection = async (data) => {
    const registerCall = data.call ? ["call"] : [];
    const registerSms = data.sms ? ["sms"] : [];

    let telephonySettings = {
      telephonySettings: {
        telephonyProvider: "twilio",
        twilioSettings: {
          twilioAccountSID: data.twilioAccountSID,
          twilioAuthToken: data.twilioAuthToken,
          twilioPhoneNumber: data.twilioPhoneNumber,
        },
        registerNumber: [...registerCall, ...registerSms],
      },
    };

    try {
      const response = await thirdPartyServices.thirdpartyUpdate(
        telephonySettings,
        projectId
      );
      if (response.status === 200) {
        message.success("Twilio connected");
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 500
      ) {
        message.error(ex.response.data.msg);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div class="flex flex-col items-center">
        <div className="w-full max-w-md p-8 border rounded-lg">
          <div className="flex gap-x-2 mb-4">
            <img src={Twilio} width={24} />
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Connect Twilio account
            </h3>
          </div>

          <div>
            <Form
              key={JSON.stringify(formFieldforTwilio)}
              errorMessage={errorMessage}
              inputField={formFieldforTwilio}
              template={templateTwilio}
              schema={schemaTwilio}
              buttonInfo={buttonInfoTwilio}
              onSubmit={handleTwilioConnection}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwilioComponent;
