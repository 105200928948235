import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import auth from '../services/authService';

function ProtectedRoute({ children }) {
  const location = useLocation();
  const user = auth.getCurrentUser();
  
  if (!user) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
}




export default ProtectedRoute;

