import { DialogActions, Dialog, DialogTitle, DialogDescription } from "V2.0/common/dialog";
import { Button } from "V2.0/common/button";

export function DeleteAgent({ isOpen, onClose, onDelete }) {
  return (
    <Dialog className="" open={isOpen} onClose={onClose} size="lg">
      <DialogTitle>
        <div class="text-lg flex flex-row justify-between">
          <p>Delete Agent</p>
        </div>
      </DialogTitle>
      <DialogDescription>
        Hi
      </DialogDescription>
      <DialogActions>
        <Button plain onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onDelete}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}
