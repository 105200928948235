import React, { useState } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as messengerService from "../../services/messengerService";
import { message } from "antd";
import CopyInput from "V2.0/common/CopyInput";

import MessengerIcon from "V2.0/images/messenger.svg";

function Messenger({ projectId }) {
  const [loading, setLoading] = useState(false);

  const [inputFieldForMessenger] = useState({
    messengerToken: "",
    pageID: "",
  });

  const [errorMessageForMessager] = useState({});

  const templateMessenger = [
    {
      name: "messengerToken",
      label: "Messenger Token",
      type: "text",
      style: "",
    },
    {
      name: "pageID",
      label: "pageID",
      type: "text",
      style: "",
    },
  ];

  const buttonInfoforMessenger = {
    label: "Connect Messenger",
    style: "entire-width",
  };

  const schemaforMessener = {
    messengerToken: Joi.string().max(300).required().label("Messenger Token"),
    pageID: Joi.string().max(50).required().label("pageID"),
  };

  const handleMessenger = async (data) => {
    const updatedObj = {};

    for (let key in data) {
      if (typeof data[key] === "string") {
        updatedObj[key] = data[key].trim();
      } else {
        updatedObj[key] = data[key];
      }
    }

    try {
      const response = await messengerService.manageMessenger(
        projectId,
        updatedObj
      );

      if (response.status === 200) {
        message.success("Messenger configuration saved");
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);
        message.error(ex.response.data.msg);
        return;
      }
    }
  };

  return (
    <div>
      <div class="flex flex-col items-center">
        <div className="w-full max-w-md p-8 border rounded-lg">
          <div className="flex gap-x-2 mb-4">
            <img src={MessengerIcon} width={24} />
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Connect Messenger
            </h3>
          </div>
          <div>
            <div className="my-5">
              <CopyInput
                value="https://api.superdashhq.com/api/messenger/webhook"
                title="Messenger Webhook URL"
              />
            </div>

            <div className="my-5">
              <CopyInput
                value="4915359cede4b64cb190f02bc1b396af"
                title="Verify token"
              />
            </div>
            <div>
              <Form
                key={JSON.stringify(inputFieldForMessenger)}
                errorMessage={errorMessageForMessager}
                inputField={inputFieldForMessenger}
                template={templateMessenger}
                schema={schemaforMessener}
                buttonInfo={buttonInfoforMessenger}
                onSubmit={handleMessenger}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          </div>

          {/* New instructions at the bottom */}
          <div className="mt-6 text-sm text-gray-500">
            <p>
              Please find instructions to integrate Messenger{" "}
              <a
                href="https://superdashhq.notion.site/Super_-WhatsApp-Integration-Instructions-17933f1b92d44e5588ad510dc2885995"
                className="text-blue-500 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Messenger;
