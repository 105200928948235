import {
  getFieldTemplates,
  getFieldSchema,
  fields,
} from "V2.0/HomePages/Contacts/models/template";

const fieldValues = {
  firstName: "",
  lastName: "",
  email: "",
};

const { fieldTemplates, fieldSchema } = (() => {
  const { firstName, lastName, email } = fields;

  const fieldTemplates = getFieldTemplates({ firstName, lastName, email });
  const fieldSchema = getFieldSchema({ firstName, lastName, email });

  return { fieldTemplates, fieldSchema };
})();

function addContactAttributesIfPresent(data) {
  const { firstName, lastName, email } = data;
  return {
    ...((firstName || lastName || email) && {
      attributes: {
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
        ...(email && { email }),
      },
    }),
  };
}

export { fieldTemplates, fieldSchema, fieldValues, addContactAttributesIfPresent };
