import React, { useState } from "react";

import * as agentservice from "V2.0/services/humanAgentService";
import { message } from "antd";
import { TableComponent } from "V2.0/HomePages/HomeModule/CustomAPIs/components/TableComponent";
import { EditAgent } from "../Edit_agent";
import { DeleteAgent } from "../Delete_agent";

export function Agents({ projectId, agents, refetchAgents }) {
  const [dialogState, setDialogState] = useState({
    mode: null,
    isOpen: false,
    item: null,
  });

  const openEditDialog = (item) => {
    setDialogState({
      mode: "EDIT",
      isOpen: true,
      item,
    });
  };

  const openDeleteDialog = (item) => {
    setDialogState({
      mode: "DELETE",
      isOpen: true,
      item,
    });
  };

  const closeDialog = () => {
    setDialogState({
      mode: null,
      isOpen: false,
      item: null,
    });
  };

  const handleDeleteConfirmation = async () => {
    try {
      const response = await agentservice.deleteHumanAgent(
        projectId,
        dialogState.item._id
      );

      if (response.status === 200) {
        refetchAgents();
        message.success("Agent deleted");
        closeDialog();
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
    }
  };

  const data =
    agents && agents.humanAgents?.length > 0
      ? agents.humanAgents.filter((agent) => agent.contactMedium === "phone")
      : [];

  return (
    <div>
      <TableComponent
        headers={[
          { label: "Agent Name", key: "name" },
          { label: "Phone Number", key: "phoneNumber" },
          { label: "Keyword", key: "searchTerm" },
        ]}
        data={data}
        onEdit={(item) => openEditDialog(item)}
        onDelete={(item) => openDeleteDialog(item)}
      />
      {dialogState.mode === "DELETE" && (
        <DeleteAgent
          isOpen={dialogState.isOpen}
          onClose={closeDialog}
          onDelete={handleDeleteConfirmation}
        />
      )}
      {dialogState.mode === "EDIT" && (
        <EditAgent
          isOpen={dialogState.isOpen}
          onClose={closeDialog}
          agent={dialogState.item}
          refetchAgents={refetchAgents}
          projectId={projectId}
        />
      )}
    </div>
  );
}
