import React, { useState } from "react";
import { Button } from "../../common/button";

function ZapierComponent() {
  const redirectToFacebook = () => {
    window.open(
      "https://zapier.com/developer/public-invite/185868/3a933837e8ba5a264d93fd0ea74ad01f/",
      "_blank"
    );
  };

  return (
    <div>
      <div class="flex flex-col items-center">
        <div className="w-full max-w-md px-4 py-10 sm:px-6 lg:px-8 lg:py-6 border rounded-lg">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Accept Zapier invite
          </h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
              Automate your workflows using Superdash and Zapier
              <Button className="mt-5" onClick={redirectToFacebook}>
                Connect Zapier
              </Button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZapierComponent;
