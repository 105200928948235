import { Button } from "V2.0/common/button";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export function IntegrationShell({children }) {
  const navigate = useNavigate();
  return (
    <div class="w-full flex flex-col items-center p-16">
      <div className="flex flex-col gap-y-4">
        <div>
          <Button plain onClick={() => navigate("/integrations")}>
            <ArrowLeftIcon />
            back
          </Button>
        </div>
        <div className="w-[500px]">
          {children}
        </div>
      </div>
    </div>
  );
}
