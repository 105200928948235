import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as whatsappService from "../../services/whatsappService";
import { message } from "antd";
import CopyInput from "../../common/CopyInput";
import { useOutletContext } from "react-router-dom";
import httpService from "../../services/httpService";
import TabsWithBadge from "../../common/tabsWithBadge";

function useWhatsappConfiguration(projectId) {
  const [whatsappConfiguration, setWhatsappConfiguration] = useState({
    data: {},
    loading: true,
    isConfigured: false,
  });

  const fetchWhatsappConfiguration = async (projectId) => {
    setWhatsappConfiguration((prevState) => ({ ...prevState, loading: true }));
    try {
      const response = await httpService.get(
        `/whatsapp/manage/config/${projectId}`
      );
      const data = response.data;
      setWhatsappConfiguration({ data, loading: false, isConfigured: true });
    } catch (error) {
      const response = error.response;
      if (response.status === 404) {
        setWhatsappConfiguration((prevState) => ({
          ...prevState,
          loading: false,
          isConfigured: false,
        }));
      }
      console.error(error);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchWhatsappConfiguration(projectId);
    }
  }, [projectId]);

  return {
    whatsappConfiguration,
    fetchWhatsappConfiguration,
  };
}

function Whatsapp() {
  const { activeProject, projects } = useOutletContext();
  const { whatsappConfiguration, fetchWhatsappConfiguration } =
    useWhatsappConfiguration(projects[activeProject]?._id);

  if (whatsappConfiguration.loading) {
    return <p>Hi i'm loading</p>;
  }

  if (whatsappConfiguration.isConfigured) {
    return (
      <WhatsappManager existingConfiguration={whatsappConfiguration.data} />
    );
  }

  return (
    <WhatsappForm
      projectId={projects[activeProject]?._id}
      existingConfiguration={whatsappConfiguration.data}
    />
  );
}

const WHATSAPP_TABS = {
  PHONE_NUMBERS: "Phone numbers",
  TEMPLATES: "Templates",
};

function WhatsappPhoneNumbers(props) {
  return <p>Phone numbers</p>;
}

function WhatsappTemplates(props) {
  return <p>Templates</p>;
}

const WhatsappTabs = {
  [WHATSAPP_TABS.PHONE_NUMBERS]: (props) => <WhatsappPhoneNumbers {...props} />,
  [WHATSAPP_TABS.TEMPLATES]: (props) => <WhatsappTemplates {...props} />,
};

function WhatsappManager(props) {
  const [activeTab, setActiveTab] = useState(WHATSAPP_TABS.PHONE_NUMBERS);

  const tabs = [
    { name: WHATSAPP_TABS.PHONE_NUMBERS, current: true, href: "#" },
    { name: WHATSAPP_TABS.TEMPLATES, current: false, href: "#" },
  ];

  return (
    <>
      <TabsWithBadge tabs={tabs} onTabChange={(e, {tab,index}) => setActiveTab(tab.name)} />
      {WhatsappTabs[activeTab]({})}
    </>
  );
}

export function WhatsappForm(props) {
  const { projectId, existingConfiguration } = props;
  const [loading, setLoading] = useState(false);

  const [formFieldforWhatsapp] = useState({
    wabaID: existingConfiguration?.wabaID || "",
    wabaAccessToken: existingConfiguration.wabaAccessToken,
    phoneNumberId: existingConfiguration.phoneNumberIds?.at(0) || "",
  });

  const [errorMessageWhatsapp] = useState({});

  const templateWhatsapp = [
    {
      name: "wabaID",
      label: "Whatsapp Business Account ID",
      type: "text",
      style: "",
    },
    {
      name: "wabaAccessToken",
      label: "Access token",
      type: "text",
      style: "",
    },
    {
      name: "phoneNumberId",
      label: "Phone Number ID",
      type: "text",
    },
  ];

  const buttonInfoWhatsapp = {
    label: "Connect Whatsapp",
    style: "entire-width",
  };

  const schemaWhatsapp = {
    wabaID: Joi.string()
      .max(25)
      .required()
      .label("WhatsApp Business Account ID"),
    wabaAccessToken: Joi.string().max(300).required().label("Access Token"),
    phoneNumberId: Joi.string().max(50).required().label("Phone Number ID"),
  };

  const handleWhatsapp = async (data) => {
    const updatedObj = {};

    for (let key in data) {
      if (typeof data[key] === "string") {
        updatedObj[key] = data[key].trim();
      } else {
        updatedObj[key] = data[key];
      }
    }

    try {
      const response = await whatsappService.manageWhatsapp(
        projectId,
        updatedObj
      );

      if (response.status === 200) {
        message.success("Whatsapp configuration saved");
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);
        message.error(ex.response.data.msg);
        return;
      }
    }
  };

  return (
    <div class="flex flex-col items-center">
      <div className="w-full max-w-md px-4 py-10 sm:px-6 lg:px-8 lg:py-6 border rounded-lg">
        <div>
          <div className="my-5">
            <CopyInput
              value="https://api.superdashhq.com/api/whatsapp/webhook"
              title="Whatsapp Webhook URL"
            />
          </div>

          <div className="my-5">
            <CopyInput
              value="4915359cede4b64cb190f02bc1b396af"
              title="Verify token"
            />
          </div>

          <Form
            key={JSON.stringify(formFieldforWhatsapp)}
            errorMessage={errorMessageWhatsapp}
            inputField={formFieldforWhatsapp}
            template={templateWhatsapp}
            schema={schemaWhatsapp}
            buttonInfo={buttonInfoWhatsapp}
            onSubmit={handleWhatsapp}
            loading={loading}
            setLoading={setLoading}
          />
        </div>

        {/* New instructions at the bottom */}
        <div className="mt-6 text-sm text-gray-500">
          <p>
            Please find instructions to integrate Whatsapp{" "}
            <a
              href="https://superdashhq.notion.site/Super_-WhatsApp-Integration-Instructions-17933f1b92d44e5588ad510dc2885995"
              className="text-blue-500 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default Whatsapp;
