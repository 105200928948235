import { useEffect, useState } from "react";
import { Tooltip, message } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Field, Label } from "common/fieldset";
import { Input } from "V2.0/common/input";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

function CopyInput({ value, title }) {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleCopy = () => {
    message.success("Key copied");
  };

  return (
    <CopyToClipboard text={inputValue}>
      <Field>
        <span className="flex gap-x-1">
          <Label className="flex gap-x-1">{title}</Label>
          <Tooltip title="Copy Project Id">
            <DocumentDuplicateIcon
              onClick={handleCopy}
              className="cursor-pointer size-4 text-gray-500 hover:text-gray-700"
            />
          </Tooltip>
        </span>
        <Input
          type="text"
          value={value}
          disabled
          className="w-full rounded-none"
        />
      </Field>
    </CopyToClipboard>
  );
}

export default CopyInput;