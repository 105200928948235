import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import { Button } from "../../common/button";
import { message } from "antd";
import * as thirdPartyServices from "../../services/thirdpartyService";

function VoiceSettings({ voice, projects, activeProject, fetchThirdPartySettings }) {
  const [inputField, setInputField] = useState(voice);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  useEffect(() => {
    if (voice) {
      setInputField(voice);
    }
  }, [voice]);

  const template = [
    {
      name: "voiceId",
      label: "VoiceID",
      type: "select",
      options: [
        { id: 1, value: "Eva", label: "Eva (Female, English US)" },
        { id: 65, value: "Cindy", label: "Cindy (Female,English)" },
        { id: 0, value: "Arthor", label: "Arthor (English US, Male)" },

        // { id: 61, value: "Ted", label: "Ted (Male, English US)" },
        // { id: 62, value: "Chloe", label: "Chloe (Female, English US)" },
        { id: 2, value: "Andy", label: "Andy (Male, English US)" },
        { id: 4, value: "Liz", label: "Liz (Female, English US)" },
        { id: 5, value: "Brendan", label: "Brendan (Male, English US)" },
        { id: 6, value: "Sonia", label: "Sonia (Female, English UK)" },
        { id: 9, value: "Max", label: "Max (Male, German)" },
        { id: 10, value: "Felix", label: "Felix (Male, German)" },
        { id: 12, value: "Aurora", label: "Aurora (Female, Italian)" },
        { id: 7, value: "Marco", label: "Marco (Male, Italian)" },
        { id: 13, value: "Giovanni", label: "Giovanni (Male, Italian)" },
        { id: 14, value: "Martina", label: "Martina (Female, Italian)" },
        { id: 15, value: "Leonardo", label: "Leonardo (Male, Italian)" },
        { id: 16, value: "Sofia", label: "Sofia (Female, Italian)" },
        { id: 11, value: "Nat", label: "Nat (Male, English AU)" },
        { id: 17, value: "Ann", label: "Ann (Female, English AU)" },
        { id: 18, value: "Jo", label: "Jo (Female, English AU)" },
        { id: 19, value: "Will", label: "Will (Male, English AU)" },
        { id: 20, value: "Elise", label: "Elise (Female, English AU)" },
        { id: 21, value: "Dunn", label: "Dunn (Male, English AU)" },
        { id: 8, value: "Sharma", label: "Sharma (Male, Hindi)" },
        { id: 22, value: "Shantanu", label: "Shantanu (Male, Hindi)" },
        // {
        //   id: 23,
        //   value: "Vikram",
        //   label: "Vikram (Male, Hindi - Devanagri Script Only)",
        // },
        { id: 24, value: "Kavya", label: "Kavya (Female, Hindi)" },
        { id: 25, value: "Sindhu", label: "Sindhu (Female, Hindi)" },
        { id: 26, value: "Neha", label: "Neha (Female, Hindi)" },
        // { id: 29, value: "Samantha", label: "Samantha (Female, English)" },
        { id: 30, value: "Afonso", label: "Afonso (Male, Portuguese)" },
        { id: 31, value: "Artur", label: "Artur (Male, Brazilian Portuguese)" },
        {
          id: 32,
          value: "Evora",
          label: "Evora (Female, Brazilian Portuguese)",
        },
        {
          id: 33,
          value: "Cassandra",
          label: "Cassandra (Female, Brazilian Portuguese)",
        },
        { id: 34, value: "Lisa", label: "Lisa (Female, English US)" },
        { id: 35, value: "Cart", label: "Cart (Male, English US)" },
        { id: 36, value: "Kyle", label: "Kyle (Male, English US)" },
        { id: 37, value: "Charlotte", label: "Charlotte (Female, French)" },
        { id: 38, value: "Alain", label: "Alain (Male, French)" },
        { id: 39, value: "Juliette", label: "Juliette (Female, French)" },
        { id: 40, value: "Louis", label: "Louis (Male, French)" },
        { id: 41, value: "Charles", label: "Charles (Male, French)" },
        { id: 42, value: "Lucia", label: "Lucia (Female, Spanish)" },
        { id: 43, value: "Diego", label: "Diego (Male, Spanish)" },
        { id: 44, value: "Maria", label: "Maria (Female, Spanish)" },
        { id: 45, value: "Jose", label: "Jose (Male, Spanish)" },
        { id: 46, value: "Juan", label: "Juan (Male, Spanish)" },
        { id: 47, value: "Felipe", label: "Juan (Male, Spanish)" },
        { id: 76, value: "Fernanda", label: "Fernanda (Female, Spanish)" },
        { id: 48, value: "Juan", label: "Juan (Male, Argentinian Spanish)" },
        { id: 49, value: "Ines", label: "Ines (Female, Argentinian Spanish)" },
        // { id: 50, value: "Jean", label: "Jean (Male, French)" },
        // { id: 51, value: "Madeleine", label: "Madeleine (Female, French)" },
        // { id: 52, value: "Hanna", label: "Hanna (Female, Hungarian)" },
        // { id: 53, value: "Istvan", label: "Istvan (Male, Hungarian)" },
        // { id: 54, value: "Lena", label: "Lena (Female, Hungarian)" },
        // Added voices
        // { id: 55, value: "Fen", label: "Fen (Male, Cantonese Simplified)" },
        // { id: 56, value: "Ming", label: "Ming (Female, Cantonese Simplified)" },
        // { id: 57, value: "Tao", label: "Tao (Male, Mandarin Simplified)" },
        // { id: 58, value: "Liling", label: "Liling (Female, Mandarin Simplified)" },
        // { id: 59, value: "Daiyu", label: "Daiyu (Female, Mandarin Simplified)" },
        // { id: 60, value: "Zixin", label: "Zixin (Male, Mandarin Simplified)" },
        { id: 61, value: "Sophia", label: "Sophia (Female, English US)" },
        { id: 62, value: "Ignacio", label: "Ignacio (Male, English US)" },
        { id: 63, value: "Britt", label: "Britt (Female, English)" },
        { id: 64, value: "Marcus", label: "Marcus (Male, English)" },
        { id: 66, value: "Cynthia", label: "Cynthia (Female, English)" },
        { id: 67, value: "Cath", label: "Cath (Female, English)" },
        { id: 68, value: "Nick", label: "Nick (Male, English)" },
        { id: 69, value: "Jean", label: "Jean (Male, French)" },
        { id: 70, value: "Madeleine", label: "Madeleine (Female, French)" },
        { id: 71, value: "Joelle", label: "Joelle (Female, French)" },
        { id: 72, value: "Camille", label: "Camille (Female, French)" },
        { id: 73, value: "Celine", label: "Celine (Female, French)" },
        { id: 74, value: "Jacques", label: "Jacques (Female, French)" },
        { id: 75, value: "Antoine", label: "Antoine (Female, French)" },

        { id: 77, value: "Jan", label: "Jan Schevenels (Male, Flemish)" },
        { id: 79, value: "Petra", label: "Petra (Female, Flemish)" },
        { id: 80, value: "HansC", label: "Hans (Male, Flemish)" },
        { id: 81, value: "Ben", label: "Ben van Praag (Male, Flemish)" },
        { id: 82, value: "Christian", label: "Christian - Nieuws Stijl (Male, Flemish)" },
        { id: 83, value: "Walter", label: "Walter (Male, Flemish)" },
        { id: 84, value: "Bart", label: "Bart(Male, Flemish)" },
        { id: 85, value: "Adrien", label: "Adrien (Male, French(Belgie))" },
        { id: 86, value: "Christophe", label: "Christophe (Male, French(Belgie))" }
      ],
    },
  ];

  const buttonInfo = { label: "Save", style: "right-side" };

  const schema = {
    voiceId: Joi.string().label("VoiceId"),
  };

  const handleVoiceSettings = async (data) => {
    let ttsSettings = {
      ttsSettings: {
        voiceId: data.voiceId,
      },
    };

    try {
      const response = await thirdPartyServices.thirdpartyUpdate(
        ttsSettings,
        projects[activeProject]._id
      );
      if (response.status === 200) {
        message.success("Voice updated");
        fetchThirdPartySettings();
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false); // Ensure setLoading is defined and used correctly
        message.error(ex.response.data.msg);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Form
        key={JSON.stringify(inputField)}
        errorMessage={errorMessage}
        inputField={inputField}
        template={template}
        schema={schema}
        onSubmit={handleVoiceSettings}
        loading={loading}
        setLoading={setLoading}
        // getValue ={getInputValue}
        buttonInfo={buttonInfo}
      />
    </div>
  );
}

export default VoiceSettings;
